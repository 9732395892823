import { useState } from 'react';
import CMTrophyLogo from '../../../assets/img/cm-trophy/logos.png';

const HeaderLogo = () => {
  const [currentImg, setCurrentImg] = useState(CMTrophyLogo);
  return (
    <div>
      <img src={currentImg} className={'h-14 hidden lg:inline-block cursor-pointer'} alt={''} />
      {/* <img src={KheloIndiaLogo} className={'h-12 lg:hidden cursor-pointer'} alt={''} /> */}
    </div>
  );
};

export default HeaderLogo;
