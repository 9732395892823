import { FaTwitter, FaYoutube, FaInstagram, FaFacebook } from 'react-icons/fa';
import FooterBg from 'assets/img/cm-trophy/footer-bg.png';

export default function Footer() {
  return (
    <footer className="bg-cm-blue text-white py-8 px-4 md:px-8 relative overflow-hidden font-poppins">
      <div className="container mx-auto flex flex-wrap justify-between">
        <div className="w-full md:w-1/2 mb-6 md:mb-0">
          <div className="flex items-center mb-4">
            {/* <img src="/logo1.png" alt="Tamil Nadu Logo" width={60} height={60} className="mr-4" /> */}
            {/* <img src="/logo2.png" alt="SDAT Logo" width={60} height={60} /> */}
          </div>
          <h2 className="text-xl font-bold mb-2">Sports Development Authority of Tamil Nadu</h2>
          <p className="mb-2">Jawaharlal Nehru Stadium,</p>
          <p className="mb-2">Raja Muthiah Road, Periyamet,</p>
          <p className="mb-2">Chennai - 600003</p>
          <p className="mb-2">Tamil Nadu</p>
          <p className="mb-2">
            <a
              href="mailto:sdat@tn.gov.in"
              className="hover:underline text-gray-30 ignore-style font-poppins"
            >
              sdat@tn.gov.in
            </a>
          </p>
          <p>
            <a
              href="tel:9514000777"
              className="hover:underline text-gray-30 ignore-style font-poppins"
            >
              9514000777
            </a>
          </p>
        </div>
        <div className="w-full md:w-1/2">
          <h3 className="text-xl font-bold mb-4">Links</h3>
          <ul className="space-y-2">
            <li>
              <a href="https://www.sdat.tn.gov.in/" target={'_blank'} className="hover:underline text-gray-30 ignore-style " rel="noreferrer">
                Sports Development Authority of Tamil Nadu
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="absolute top-0 right-0 w-1/3 h-full opacity-10">
        <img src={FooterBg} alt="" className={'object-cover'} />
      </div>
      <div className="absolute bottom-0 right-0 mr-4 mb-4 flex space-x-4">
        <a href="https://x.com/sportstn_" target={'_blank'} aria-label="Twitter" className={'text-gray-30 ignore-style'}>
          <FaTwitter className="text-2xl" />
        </a>
        <a href="https://www.youtube.com/@sportstamilnadu?si=Bue9wtNtVzSO8k38" target={'_blank'} aria-label="YouTube" className={'text-gray-30 ignore-style'}>
          <FaYoutube className="text-2xl " />
        </a>
        <a href="https://www.instagram.com/sportstn_?igsh=NnVkNjNrbDAyNWRq" target={'_blank'} aria-label="Instagram" className={'text-gray-30 ignore-style'}>
          <FaInstagram className="text-2xl " />
        </a>
        <a href="https://www.facebook.com/sdat.gov" aria-label="Facebook" target={'_blank'} className={'text-gray-30 ignore-style'}>
          <FaFacebook className="text-2xl " />
        </a>
      </div>

      <div className="my-8 flex text-center text-sm space-x-10">
        <p className={'w-96'}>Powered by SFA</p>
        <p className={'w-150'}>Copyright 2024 Sports Development Authority of Tamil Nadu</p>
      </div>
    </footer>
  );
}
