import Loader from 'components/Loader';
import { useTournamentSportList } from 'micro-site/queries/hooks';
import { Link, useParams } from 'react-router-dom';
import Slider from 'react-slick';
import { useBreakPoint } from 'utils/HooksUtils';
import Header from './Header';

const Sports = ({ data, heading, backgroundImage, mobileBackgroundImage }) => {
  const params = useParams();
  const sportQuery = useTournamentSportList({ tournamentId: params.tournament_id });
  // console.log(sportQuery);
  const settings = {
    dots: true,
    infinite: false,
    rows: 6,
    slidesPerRow: 2,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          rows: 5,
          slidesPerRow: 2,
        },
      },
    ],
    arrows: false,
  };
  const isLg = useBreakPoint('lg');
  if (sportQuery.isFetching) return <Loader />;
  return (
    <div
      className={`${
        (isLg && backgroundImage) || (!isLg && mobileBackgroundImage) ? '' : 'bg-blue-kheloBlue'
      } px-6 lg:px-16 py-12`}
      style={{
        backgroundImage:
          isLg && backgroundImage
            ? `url('${backgroundImage}')`
            : !isLg && mobileBackgroundImage
            ? `url('${mobileBackgroundImage}')`
            : '',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className="flex justify-center items-center mb-1">
        <Header {...heading} borderWidth="60%" />
      </div>

      <div className="h-3"></div>
      <div className="grid-cols-2 lg:grid-cols-6 gap-2 mt-6 hidden lg:grid">
        {sportQuery.data?.map((el, i) => (
          <SportCard {...el} key={i} />
        ))}
      </div>
      <Slider {...settings} className={`mt-3 block lg:hidden sports-home-slider`}>
        {sportQuery.data?.map((el, i) => (
          <SportCard {...el} key={i} />
        ))}
      </Slider>
    </div>
  );
};

export default Sports;

function SportCard({ img, title: sport, link }) {
  const params = useParams();
  return (
    <Link
      className="w-47% lg:w-full inline-block lg:m-0 m-1 bg-red-500"
      to={link || `/micro-site/${params.tournament_id}/landing/sports/${sport} `}
    >
      <div className="rounded-md bg-white p-2 flex  items-center hover:bg-blue-photoCards">
        <img src={img} className="h-12" />
        <div
          className="h-full w-full flex items-center justify-center uppercase text-xxs md:text-sm font-bold"
          style={{ color: 'rgb(3, 45, 202)' }}
        >
          {sport}
        </div>
      </div>
    </Link>
  );
}
