import one from '../../assets/img/cm-trophy/1.png';
import two from '../../assets/img/cm-trophy/2.png';
import three from '../../assets/img/cm-trophy/3.png';
import four from '../../assets/img/cm-trophy/4.png';
import five from '../../assets/img/cm-trophy/5.png';
import { Link, useParams } from 'react-router-dom';

export default function CMTrophyHeader({ forMicrosite = false, showOptions = true }) {
  // get route parameter
  const { tournament_id: tournamentId } = useParams();
  const menuItems = showOptions
    ? !forMicrosite
      ? [
          {
            label: 'Home',
            path: '/',
            type: 'external',
          },
          {
            label: 'Categories',
            path: '#categories',
            type: 'internal',
          },
          {
            label: 'Sports',
            path: '#sports',
            type: 'internal',
          },
          {
            label: 'Venues',
            path: '/micro-site/242/landing/venues',
            type: 'external',
          },
          {
            label: 'Gallery',
            path: '#gallery',
            type: 'internal',
          },
          {
            label: 'About Us',
            path: '#about-us',
            type: 'internal',
          },
        ]
      : [
          {
            label: 'Home',
            path: '/',
            type: 'external',
          },
          {
            label: 'Sports',
            path: '/micro-site/' + tournamentId + '/landing/sports',
            type: 'external',
          },
          {
            label: 'Venues',
            path: '/micro-site/242/landing/venues',
            type: 'external',
          },
          {
            label: 'Schedule',
            path: '/micro-site/' + tournamentId + '/landing/schedule',
            type: 'external',
          },
          // {
          //   label: 'Gallery',
          //   path: '/#gallery',
          //   type: 'internal',
          // },
          {
            label: 'Results',
            path: '/micro-site/' + tournamentId + '/landing/results',
            type: 'external',
          },
        ]
    : [];

  return (
    <header className="bg-cm-blue text-white">
      <div className="container mx-auto md:px-4">
        <div className="flex items-center justify-between">
          <Link to="/">
            <div className="flex items-center space-x-4">
              <img src={one} alt="Emblem" className="md:w-16 md:h-16 w-10 h-10 rounded-full" />
              <img src={two} alt="SDAT Logo" className="md:w-16 md:h-16 w-10 h-10" />
              <img src={three} alt="Stylized Logo" className="md:w-16 md:h-16 w-10 h-10" />
            </div>
          </Link>
          {!showOptions && (
            <>
              <p className="text-xl font-bold text-gray-15 hover:text-gray-20 ignore-style hidden md:block">
                {'Sports Development Authority of Tamil Nadu'}
              </p>
              <p className="text-xl font-bold text-gray-15 hover:text-gray-20 ignore-style block md:hidden">
                {'SDAT'}
              </p>
            </>
          )}

          {showOptions && (
            <ul className="hidden md:flex h-16">
              {menuItems.map((item, index) => {
                return item.type === 'internal' ? (
                  <a
                    href={item.path}
                    key={index}
                    className={'flex items-center justify-center p-3 px-4'}
                  >
                    <li className="text-sm font-bold text-gray-15 hover:text-gray-20 ignore-style">
                      {item.label}
                    </li>
                  </a>
                ) : (
                  <Link
                    to={item.path}
                    key={index}
                    className={'flex items-center justify-center p-3 px-4'}
                  >
                    <li className="text-sm font-bold text-gray-15 hover:text-gray-20 ignore-style">
                      {item.label}
                    </li>
                  </Link>
                );
              })}
            </ul>
          )}
          <div className="flex items-center space-x-2">
            <img
              src={four}
              alt="Profile 1"
              className="md:w-16 md:h-16 w-10 h-10 rounded-full bg-white"
            />
            <img
              src={five}
              alt="Profile 2"
              className="md:w-16 md:h-16 w-10 h-10 rounded-full bg-white"
            />
            {showOptions && (
              <Link to="/signin" className={'flex items-center justify-center p-3 px-4'}>
                <p className="text-sm font-bold text-gray-15 hover:text-gray-20 ignore-style">
                  Login
                </p>
              </Link>
            )}
          </div>
        </div>
      </div>
      <div className="h-1 bg-[#87CEEB]"></div>
    </header>
  );
}
