import React, { useState, useEffect } from 'react';
import CMTrophyHeader from '../micro-site/Components/CMTrophyHeader';
import WinnerMobile from '../assets/Winners-mobile.png';
import Winner from '../assets/Winners-desktop.png';
import { filter } from 'lodash';

export default function Winners() {
  const [athletes, setAthletes] = useState([]);
  const [filteredAthletes, setFilteredAthletes] = useState([]);
  // const [displayFilters, setDisplayFilters] = useState(false);
  const [search, setSearch] = useState('');
  const isMobile = window.innerWidth < 768;
  const [filters, setFilters] = useState({
    district: '',
    category: '',
    gender: '',
    discipline: '',
    event: '',
  });

  useEffect(() => {
    fetchAthletes();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [athletes, search, filters]);

  const fetchAthletes = async () => {
    try {
      const response = await fetch(
        'https://distadmin.cmtrophytamilnadu.in/api/resource/Winner?fields=[%22*%22]&limit=50000'
      );
      const data = await response.json();

      setAthletes(
        data.data.map((item) => ({
          name: item.name.split('-')[0],
          discipline: item.sport,
          gender: item.gender,
          district: item.district,
          category: item.category,
          medal: item.medal,
          participant: item.participant,
          participant_name: item.participant_name,
          team_name: item.team_name,
          event: item.event_category,
        }))
      );
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const applyFilters = () => {
    let filtered = athletes.filter((athlete) =>
      (athlete.participant_name || '').toLowerCase().includes(search.toLowerCase())
    );

    Object.entries(filters).forEach(([key, value]) => {
      if (value) {
        filtered = filtered.filter((athlete) => athlete[key] === value);
      }
    });

    const medalOrder = { Gold: 1, Silver: 2, Bronze: 3 }

    filtered.sort((a, b) => medalOrder[a.medal] - medalOrder[b.medal])

    setFilteredAthletes(filtered);
  };

  const handleFilterChange = (filterName, value) => {
    setFilters((prev) => ({ ...prev, [filterName]: value }));
  };

  const uniqueValues = (key) => {
    return Array.from(new Set(athletes.map((athlete) => athlete[key])))
      .filter(Boolean)
      .sort((a, b) => a.localeCompare(b));
  };

  return (
    <>
      <CMTrophyHeader showOptions={false} />
      <div className="p-4 bg-gray-100 min-h-screen">
        <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-md overflow-hidden">
          <img src={isMobile ? WinnerMobile : Winner} alt="Winners" className="w-full" />

          <div className="p-4 border-b border-gray-200">
            <div className="flex items-center space-x-2 mb-4">
              <div className="relative flex-grow">
                <input
                  type="text"
                  placeholder="Search by Name"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className="w-full pl-8 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-gray-400 absolute left-2 top-2.5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              {/* <button
                className={
                  'p-2 bg-gray-200 rounded-md focus:outline-none ' +
                  (displayFilters ? 'ring-2 ring-blue-500' : ' ring-0')
                }
                onClick={() => {
                  setDisplayFilters(!displayFilters);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-gray-600"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                    clipRule="evenodd"
                  />
                </svg>
              </button> */}
            </div>
            <div className="flex gap-4 flex-wrap">
              {Object.keys(filters).map((filter) => (
                <select
                  key={filter}
                  value={filters[filter]}
                  onChange={(e) => handleFilterChange(filter, e.target.value)}
                  className="p-2 border border-gray-300 rounded-md text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 min-w-32 "
                >
                  <option value="">{filter.charAt(0).toUpperCase() + filter.slice(1)}</option>
                  {uniqueValues(filter).map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              ))}
            </div>
          </div>
          <div className={'w-full overflow-y-scroll'}>
            {filteredAthletes.length > 0 ? (
              <table className="w-full">
                <thead>
                  <tr className="bg-gray-50">
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Name of the player / Team
                    </th>
                    <th className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      District / Regional
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Sports Discipline
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Event
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Medal
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {filteredAthletes.map((athlete, index) => (
                    <tr key={index}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900">
                          {athlete.participant_name || athlete.team_name}
                        </div>
                        <div className="text-sm text-gray-500">
                          {athlete.gender} {athlete.participant}
                        </div>
                      </td>
                      <td>
                        <div className="text-sm font-medium text-gray-900">{athlete.district}</div>
                        <div className="text-sm font-medium text-gray-900">
                          {athlete.district}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">{athlete.discipline}</div>
                        <div className="text-sm text-gray-500">{athlete.category}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">{athlete.event}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-lg bg-green-100 text-green-800">
                          {athlete.medal === 'Gold' && '🥇'}
                          {athlete.medal === 'Silver' && '🥈'}
                          {athlete.medal === 'Bronze' && '🥉'}
                          {athlete.medal}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="text-center py-8">
                <p className="text-gray-500 text-lg">Winners will be updated soon</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
